export const lmap = (val, inMin, inMax, outMin, outMax) => {
	return outMin + ((outMax - outMin) * (val - inMin)) / (inMax - inMin);
}

export const lmapc = (val, inMin, inMax, outMin, outMax) => {
	return Math.max(0.0, Math.min(1.0, lmap(val, inMin, inMax, outMin, outMax) ));
}

// export const randBetween = (min, max) => {
// 	return Math.random() * (max - min) + min;
// }

export const rotate2d = (x, y, pivotX, pivotY, rad) => {
	let x1 = x - pivotX;
	let y1 = y - pivotY;

	return {
		x: pivotX + x1 * Math.cos(rad) - y1 * Math.sin(rad),
		y: pivotY + y1 * Math.cos(rad) + x1 * Math.sin(rad)
	}
}

export const smoothstep = (edge0, edge1, val) => {
	// Scale, bias and saturate x to 0..1 range
	let x = Math.max(0.0, Math.min(1.0, (val - edge0) / (edge1 - edge0)) );
	// Evaluate polynomial
	return x * x * (3.0 - 2.0 * x);
}

/*
easeInOutQuad(x) {
		return x < 0.5 ? 2 * x * x : 1 - Math.pow(-2 * x + 2, 2) / 2;
	}
	
	easeInOutCirc(x) {
		return x < 0.5
		  ? (1 - Math.sqrt(1 - Math.pow(2 * x, 2))) / 2
		  : (Math.sqrt(1 - Math.pow(-2 * x + 2, 2)) + 1) / 2;
	}
	
	easeInOutExpo(x) {
		return x === 0
		  ? 0
		  : x === 1
		  ? 1
		  : x < 0.5 ? Math.pow(2, 20 * x - 10) / 2
		  : (2 - Math.pow(2, -20 * x + 10)) / 2;
	}
*/