// export default [
// 	[
// 		{svg:"./svg/Asset_2_0.svg", pos:[0.0, 0.0], 	scale:[0.0, 0.0]},
// 		{svg:"./svg/Asset_2_1_1.svg", pos:[0.0, 0.0], 	scale:[0.0, 0.0]}, 
// 		// {svg:"./svg/Asset_2_2.svg", pos:[0.0, 0.0], 	scale:[0.0, 0.0]}
// 	]
// ]


export default [
	[
		{svg:"./svg/Asset_0_0.svg", 	dex:0, pos:[0,0], 			scale:[0.5, 0.22], 	color:0x178000, 
			anim:[
				{layer: 1, type:"scale", center:{x:297, y:313}, min:-0.8, max:0.0, skip:"odd" },
				{layer: 1, type:"rotate", center:{x:297, y:313}, min:-15, max: 15, skip:"odd"}
			]
		},
		{svg:"./svg/Asset_0_1.svg", 	dex:1, pos:[0.518, 0.0], 	scale:[0.25, 0.22], color:0xC2C2C2,
			anim:[
				{layer: 1, type:"scale", center:{x:143, y:313}, min:-0.2, max:0.0, skip:"odd" },
				{layer: 1, type:"translate", bounds:[-5, 5, 0.0, 200], skip:"odd"}
			]},
		{svg:"./svg/Asset_0_2.svg", 	dex:2, pos:[0.768, 0.0], 	scale:[0.25, 0.22], color:0xFF0000,
			anim:[
				{layer: 1, type:"morph", asset:"./svg/Asset_0_2_Anim.svg"},
			],
		}
	],[	
		{svg:"./svg/Asset_1_0.svg", 	dex:3, pos:[0.0, 0.22],		scale:[1.0, 0.22],	color:0xC2C2C2,
			anim:[
				{layer: 1,  type:"morph", asset:"./svg/Asset_1_0_Anim.svg"},
				{layer: 2,  type:"morph", asset:"./svg/Asset_1_0_Anim.svg"},
				{layer: 3,  type:"morph", asset:"./svg/Asset_1_0_Anim.svg"},
				{layer: 4,  type:"morph", asset:"./svg/Asset_1_0_Anim.svg"},
				{layer: 5,  type:"morph", asset:"./svg/Asset_1_0_Anim.svg"},
				{layer: 6,  type:"morph", asset:"./svg/Asset_1_0_Anim.svg"},
				{layer: 7,  type:"morph", asset:"./svg/Asset_1_0_Anim.svg"},
				{layer: 8,  type:"morph", asset:"./svg/Asset_1_0_Anim.svg"},
				{layer: 9,  type:"morph", asset:"./svg/Asset_1_0_Anim.svg"},
				{layer: 10, type:"morph", asset:"./svg/Asset_1_0_Anim.svg"},
				{layer: 11, type:"morph", asset:"./svg/Asset_1_0_Anim.svg"},
				{layer: 12, type:"morph", asset:"./svg/Asset_1_0_Anim.svg"},
				{layer: 13, type:"morph", asset:"./svg/Asset_1_0_Anim.svg"},
			],
			seed:"rand_1_0"
		} 
	],[
		{svg:"./svg/Asset_2_0.svg", 	dex:4, pos:[0.0, 0.0], 		scale:[0.0, 0.0],	color:0xFF0000,
			anim:[
				{layer: 1, type:"translate", bounds:[-5, 5, -75, 75], skip:"even"},
				{layer: 2, type:"translate", bounds:[-5, 5, -55, 55] },
				{layer: 3, type:"translate", bounds:[-5, 5, -55, 55] },
				{layer: 4, type:"translate", bounds:[-5, 5, -55, 55] },
				{layer: 6, type:"translate", bounds:[-5, 5, -55, 55] },
				{layer: 5, type:"translate", bounds:[-5, 5, -75, 75] },
			],
			seed:"rand_2_0"
		},
		{svg:"./svg/Asset_2_1.svg", 	dex:5, pos:[0.0, 0.0], 		scale:[0.0, 0.0],	color:0xC2C2C2, 
			anim:[
				{layer: 106, type:"morph", asset:"./svg/Asset_2_1_Anim_ML.svg"},
			]
		}, 
		{svg:"./svg/Asset_2_2.svg", 		dex:6, pos:[0.0, 0.0], 		scale:[0.0, 0.0],	color:0x000000,
			anim:[
				{layer: 1, type:"morph", asset:"./svg/Asset_2_2_Anim.svg"},
				{layer: 2, type:"morph", asset:"./svg/Asset_2_2_Anim.svg"}
			],
			seed:"seed_2_2"
		}
	],[
		{svg:"./svg/Asset_3_0.svg",  	dex:7,pos:[0.0, 0.22],		scale:[1.0, 0.22],	color:0xC2C2C2,
			anim:[
				{layer: 1,  type:"morph", asset:"./svg/Asset_3_0_Anim.svg"},
				{layer: 2,  type:"morph", asset:"./svg/Asset_3_0_Anim.svg"},
				{layer: 3,  type:"morph", asset:"./svg/Asset_3_0_Anim.svg"},
				{layer: 4,  type:"morph", asset:"./svg/Asset_3_0_Anim.svg"},
				{layer: 5,  type:"morph", asset:"./svg/Asset_3_0_Anim.svg"},
				{layer: 6,  type:"morph", asset:"./svg/Asset_3_0_Anim.svg"},
				{layer: 7,  type:"morph", asset:"./svg/Asset_3_0_Anim.svg"},
				{layer: 8,  type:"morph", asset:"./svg/Asset_3_0_Anim.svg"},
				{layer: 9,  type:"morph", asset:"./svg/Asset_3_0_Anim.svg"},
				{layer: 10, type:"morph", asset:"./svg/Asset_3_0_Anim.svg"},
				{layer: 11, type:"morph", asset:"./svg/Asset_3_0_Anim.svg"},
				{layer: 12, type:"morph", asset:"./svg/Asset_3_0_Anim.svg"},
				{layer: 13, type:"morph", asset:"./svg/Asset_3_0_Anim.svg"},
			],
			seed:"rand_3_0"
	}
	],[	
		{svg:"./svg/Asset_4_0.svg",  	dex:8, pos:[0.0, 0.0], 		scale:[0.0, 0.0],	color:0x1600DE,
			anim:[
				{layer: 1, type:"morph", asset:"./svg/Asset_4_0_Anim_1.svg"}
			]
		},
		{svg:"./svg/Asset_4_1.svg",  	dex:9, pos:[0.0, 0.0], 		scale:[0.0, 0.0],	color:0xC2C2C2,
			anim:[
				{layer: 1, type:"morph", asset:"./svg/Asset_4_1b_Anim_ML.svg"}
			],
		}, 
		{svg:"./svg/Asset_4_2.svg",  	dex:10, pos:[0.0, 0.0], 	scale:[0.0, 0.0],	color:0x1600DE,
			anim:[
				{layer: 1, type:"scale", center:{x:132, y:52}, min:-0.3, max:1.4, skip:"even" },
				{layer: 2, type:"scale", center:{x:239, y:14}, min:0.1, max:-0.3 },
				{layer: 3, type:"scale", center:{x:103, y:17}, min:0.1, max:-0.3 },
				{layer: 6, type:"scale", center:{x:28, y:47},  min:0.1, max:-0.3 },
				{layer: 4, type:"scale", center:{x:103, y:87}, min:0.1, max:-0.3 },
				{layer: 5, type:"scale", center:{x:238, y:89}, min:0.1, max:-0.3 },
			],
			seed:"seed_4_2"
		}
	],[	
		{svg:"./svg/Asset_5_0.svg",  	dex:11, pos:[0.0, 0.22],	scale:[1.0, 0.22],	color:0xC2C2C2,
			anim:[
				{layer: 1,  type:"morph", asset:"./svg/Asset_5_0_Anim_1.svg"},
				{layer: 2,  type:"morph", asset:"./svg/Asset_5_0_Anim_1.svg"},
				{layer: 3,  type:"morph", asset:"./svg/Asset_5_0_Anim_1.svg"},
				{layer: 4,  type:"morph", asset:"./svg/Asset_5_0_Anim_1.svg"},
				{layer: 5,  type:"morph", asset:"./svg/Asset_5_0_Anim_1.svg"},
				{layer: 6,  type:"morph", asset:"./svg/Asset_5_0_Anim_1.svg"},
				{layer: 7,  type:"morph", asset:"./svg/Asset_5_0_Anim_1.svg"},
				{layer: 8,  type:"morph", asset:"./svg/Asset_5_0_Anim_1.svg"},
				{layer: 9,  type:"morph", asset:"./svg/Asset_5_0_Anim_1.svg"},
				{layer: 10, type:"morph", asset:"./svg/Asset_5_0_Anim_1.svg"},
				{layer: 11, type:"morph", asset:"./svg/Asset_5_0_Anim_1.svg"},
				{layer: 12, type:"morph", asset:"./svg/Asset_5_0_Anim_1.svg"},
				{layer: 13, type:"morph", asset:"./svg/Asset_5_0_Anim_1.svg"},
			],
			seed:"rand_5_0"
	}
	],[
		{svg:"./svg/Asset_6_0.svg", 	dex:12, pos:[0.0, 0.0], 	scale:[0.0, 0.0],	color:0xC2C2C2,
			anim:[
				{layer: 1, type:"rotate", center:{x:143, y:202}, min:0, max: 45, skip:"odd"},
				{layer: 1, type:"translate", bounds:[0, 0, -100, 0], skip:"odd"}
			]
		},
		{svg:"./svg/Asset_6_1.svg", 	dex:13, pos:[0.0, 0.0], 	scale:[0.0, 0.0],	color:0x178000,
			anim:[
				{layer: 1, type:"morph", asset:"./svg/Asset_6_1_Anim.svg"}
			]
		}, 
		{svg:"./svg/Asset_6_2.svg", 	dex:14, pos:[0.0, 0.0], 	scale:[0.0, 0.0],	color:0xFF0000,
			anim:[
				{layer: 1, type:"scale", center:{x:132, y:202}, min:-0.35, max:0.1, skip:"even" },
				{layer: 1, type:"translate", bounds:[-15, 15, -30, 30], skip:"even"},
			],
	}
	],[	
		{svg:"./svg/Asset_7_0.svg", 	dex:15, pos:[0.0, 0.22],	scale:[1.0, 0.22],	color:0xC2C2C2,
			anim:[
				{layer: 1,  type:"morph", asset:"./svg/Asset_7_0_Anim_1.svg"},
				{layer: 2,  type:"morph", asset:"./svg/Asset_7_0_Anim_1.svg"},
				{layer: 3,  type:"morph", asset:"./svg/Asset_7_0_Anim_1.svg"},
				{layer: 4,  type:"morph", asset:"./svg/Asset_7_0_Anim_1.svg"},
				{layer: 5,  type:"morph", asset:"./svg/Asset_7_0_Anim_1.svg"},
				{layer: 6,  type:"morph", asset:"./svg/Asset_7_0_Anim_1.svg"},
				{layer: 7,  type:"morph", asset:"./svg/Asset_7_0_Anim_1.svg"},
				{layer: 8,  type:"morph", asset:"./svg/Asset_7_0_Anim_1.svg"},
				{layer: 9,  type:"morph", asset:"./svg/Asset_7_0_Anim_1.svg"},
				{layer: 10, type:"morph", asset:"./svg/Asset_7_0_Anim_1.svg"},
				{layer: 11, type:"morph", asset:"./svg/Asset_7_0_Anim_1.svg"},
				{layer: 12, type:"morph", asset:"./svg/Asset_7_0_Anim_1.svg"},
				{layer: 13, type:"morph", asset:"./svg/Asset_7_0_Anim_1.svg"},
			],
			seed:"rand_7_0"
		}
	]
]
