//* 8thWall Three.js pipeline *//
export function CreateThreePipeline() {
  let threeRefs;

  function getCustomThreeObjects() {
    return threeRefs;
  }

  function customThreePipeline() {
    return {
      name: "three",
      onStart({ canvas, canvasWidth, canvasHeight, GLctx }) {
        const camera = new THREE.PerspectiveCamera(
          60.0 /* initial field of view; will get set based on device info later. */,
          canvasWidth / canvasHeight,
          0.01,
          100.0
        );

        //
        const gl = new THREE.WebGLRenderer({
          canvas,
          context: GLctx,
          alpha: true,
          antialias: true,
        });

        gl.pixelRatio = window.devicePixelRatio;
        gl.shadowMap.enabled = true;
        gl.shadowMap.type = THREE.PCFSoftShadowMap;
        gl.outputEncoding = THREE.sRGBEncoding;
        gl.toneMapping = THREE.LinearToneMapping;

        gl.setSize(canvasWidth, canvasHeight);

        threeRefs = { camera, gl, GLctx };

        // window.addEventListener('resize', this.onResize);

        return threeRefs;
      },

      onAttach(event) {
        return threeRefs;
      },

      onResize(event){
        // const { gl, camera } = threeRefs;
        // gl.setSize(window.innerWidth, window.innerHeight);

        // camera.aspect = window.innerWidth / window.innerHeight;
        // camera.updateProjectionMatrix();
      },

      onCanvasSizeChange({ canvasWidth, canvasHeight }) {
        const { gl, camera } = threeRefs;
        gl.setSize(canvasWidth, canvasHeight);

        camera.aspect = canvasWidth / canvasHeight;
        camera.updateProjectionMatrix();
      },

      onUpdate({ processCpuResult }) {
        if (!processCpuResult.reality) {
          return;
        }

        const { rotation, position, intrinsics } = processCpuResult.reality;
        const { camera } = threeRefs;

        for (let i = 0; i < 16; i++) {
          camera.projectionMatrix.elements[i] = intrinsics[i];
        }

        if (rotation) {
          camera.setRotationFromQuaternion(rotation);
        }
        if (position) {
          camera.position.set(position.x, position.y, position.z);
        }
      },
    };
  }

  return {
    getCustomThreeObjects: getCustomThreeObjects,
    pipelineModule: customThreePipeline,
  };
}
