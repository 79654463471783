import { SVGLoader } from 'three/examples/jsm/loaders/SVGLoader'
import { lmap, rotate2d, smoothstep } from "./Utils/Remap";

export default class ShapeGeometryAnim extends THREE.ShapeGeometry {
	constructor(shapeData){
		super(shapeData);

		// copy the original positions
		this.op = [...this.attributes.position.array];
		this.lastPct = 0.0;
		this.animDone = false;
		this.animDataList = [];
		this.seedrandom = require('seedrandom');
		this.rng = this.seedrandom();

		let cubicbezier = require('bezier-easing');
		this.easing = cubicbezier(.46,.01,.75,.98);
	}

	setRandomSeed(seed){
		this.rng = this.seedrandom(seed);
		return this;
	}

	addAnim(_animData){
		this.animDataList.push(_animData);
		this.animDataList[this.animDataList.length-1].needsRandomize = true;
		return this;
	}

	update(et, pct){
		// timing
		this.animDone = (pct - this.lastPct) < 0;
		if(this.animDone){ this.animDataList.forEach( data => data.needsRandomize = true); }
		this.lastPct = pct;
		// pct = smoothstep(0.0, 1.0, pct);	// shape the pct to ease in/out
		pct = this.easing(pct);

		// update the buffer attribute
		const itemSize = this.attributes.position.itemSize; // This should be 3 for x,y,z
		let dex = 0;
		for(let i=0; i<this.attributes.position.array.length; i+=itemSize){
			let tmpX = this.op[i];
			let tmpY = this.op[i+1];
			let tmpZ = this.op[i+2];

			// run through all animations acting on this shape
			for(let j=0; j<this.animDataList.length; j++){
				let ad = this.animDataList[j];

				// Check if we should skip every other vert because these are spikys
				if(ad.skip){
					if(ad.skip == "odd" && dex % 2 == 0)
						continue;
					else if(ad.skip == "even" && dex % 2 == 1)
						continue;
				}

				// Now apply all animations ----------------------------------

				// SCALE
				let scObj;
				switch (ad.type) {
					case "scale":
						scObj = this.scale(tmpX, tmpY, ad, pct); break;
					case "translate":
						scObj = this.translate(tmpX, tmpY, ad, pct); break;
					case "rotate":
						scObj = this.rotate(tmpX, tmpY, ad, pct); break;
					case "morph":
						scObj = this.morph(tmpX, tmpY, ad, pct, i); break;
					default:
						break;
				}
				
				tmpX = scObj.x;
				tmpY = scObj.y;
			}

			// Apply transformed vert positions
			this.attributes.position.array[i] 	= tmpX;
			this.attributes.position.array[i+1] = tmpY;
			
			++dex;
		}

		// set needsUpdate = true to resend to GPU
		this.attributes.position.needsUpdate = true;
		return this;
	}

	rand(){
		// return 1;
		return this.rng();
	}

	randBetween(min, max) {
		return this.rand() * (max - min) + min;
	}

	scale(x, y, anim, pct){
		if(anim.needsRandomize){
			anim.needsRandomize = false;
			anim.cur = (anim.dest) ? anim.dest : 0.0;
			anim.dest = this.randBetween(anim.min, anim.max);
		}

		let amt = lmap(pct, 0.0, 1.0, anim.cur, anim.dest);
		
		// get the vec from center
		var x1 = x + (x - anim.center.x) * amt;
		var y1 = y + (y - anim.center.y) * amt;

		return { x:x1, y:y1 };
	}

	translate(x, y, anim, pct){
		if(anim.needsRandomize){
			anim.needsRandomize = false;
			anim.cur = (anim.dest) ? anim.dest : {x:0.0, y:0.0};
			anim.dest = { 	x: this.randBetween(anim.bounds[0], anim.bounds[1]),
							y: this.randBetween(anim.bounds[2], anim.bounds[3]) };
		}

		let x1 = x + lmap(pct, 0.0, 1.0, anim.cur.x, anim.dest.x);
		let y1 = y + lmap(pct, 0.0, 1.0, anim.cur.y, anim.dest.y);

		return { x:x1, y:y1 };
	}

	rotate(x, y, anim, pct){
		if(anim.needsRandomize){
			anim.needsRandomize = false;
			anim.cur = (anim.dest) ? anim.dest : 0;
			anim.dest = this.randBetween(anim.min * 3.14159/180.0, anim.max * 3.14159/180.0);
		}

		let rotAmt = lmap(pct, 0.0, 1.0, anim.cur, anim.dest);
		let rot = rotate2d(x, y, anim.center.x, anim.center.y, rotAmt);

		return { x:rot.x, y:rot.y };
	}

	morph(x, y, anim, pct, vertIndex){
		let nx = x;
		let ny = y;

		if(this.morphAnim == undefined){
			this.morphAnim = {};
			this.morphAnim.loaded = false;
			
			new Promise( (resolve) => { new SVGLoader().load(anim.asset, resolve) }).then( (loadedData) =>{
				this.morphAnim.target = new THREE.ShapeGeometry( loadedData.paths[anim.layer].toShapes() );
				this.morphAnim.loaded = true;
			});
		}else if(this.morphAnim.loaded == true){
			if(anim.needsRandomize){
				anim.needsRandomize = false;
				anim.cur = (anim.dest) ? anim.dest : 0;
				anim.dest = this.rand();
			}

			let dstX = this.morphAnim.target.attributes.position.array[vertIndex];
			let dstY = this.morphAnim.target.attributes.position.array[vertIndex+1];

			let fromX = (dstX - x) * anim.cur;
			let fromY = (dstY - y) * anim.cur;
			let toX = (dstX - x) * anim.dest;
			let toY = (dstY - y) * anim.dest;

			nx = x + lmap(pct, 0.0, 1.0, fromX, toX);
			ny = y + lmap(pct, 0.0, 1.0, fromY, toY);
		}

		return { x:nx, y:ny }; 
	}
}