import React, { useEffect, useState, useRef, useMemo } from "react";
import { useThree, useFrame } from "react-three-fiber";
import { OrbitControls } from "@react-three/drei/native"; // Still preventing mouse click events
import ClickObjectInfo from "../Utils/ClickObjectInfo";

export function Emulate8thWall(props) {
  const {
    pipeline,
    background,
    cameraPosition,
    useOrbitControls,
    useClickDebug,
  } = props;

  const [started, setStarted] = useState(false);
  const [refs, setRefs] = useState(null);
  const { setDefaultCamera, camera, scene } = useThree();
  const sceneGroup = useRef();

  useFrame(() => {
    if (refs) refs.gl.render(scene, refs.camera);
  }, 1); // disable R3F internal rendering

  useEffect(() => {
    if (refs) {
      setDefaultCamera(refs.camera);
      scene.add(sceneGroup.current);
      setStarted(true);
      console.log({ threeOjects: refs });
    }
  }, [refs]);

  useEffect(() => {
    const cvs = document.getElementsByTagName("canvas")[0]; // this should get R3F's canvas
    const pm = pipeline.pipelineModule();
    pm.onStart({
      canvas: cvs,
      canvasWidth: cvs.width,
      canvasHeight: cvs.height,
      GLctx: cvs.getContext("webgl"),
    });
    const { camera, gl } = pipeline.getCustomThreeObjects();

    // Set the initial camera position
    camera.position.fromArray(cameraPosition);
    camera.lookAt(new THREE.Vector3(0, 0, 0));

    const newRefs = {
      camera: camera,
      gl: gl,
    };
    setRefs(newRefs);

    if (background) scene.background = background;
  }, [pipeline, background]);

  return (
    <>
      <group name={"8thWall_AR_Group"} ref={sceneGroup}>
        {started && props.children}
        {useOrbitControls && <OrbitControls camera={camera} />}
        {useClickDebug && refs && (
          <ClickObjectInfo camera={refs.camera} scene={scene} gl={refs.gl} />
        )}
      </group>
    </>
  );
}

export function UserAgentSniff() {
  const mobileDetected = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
    navigator.userAgent
  );
  return { mobileDetected };
}
