import React, { useMemo } from "react";
import ReactDOM from "react-dom";
import { Canvas } from "react-three-fiber";

//* 8thWall integration *//
import { Bridge8thWall } from "./Bridge8thWall/Bridge8thWall";
import { CreateThreePipeline } from "./Bridge8thWall/CustomThreePipeline";
import { Emulate8thWall, UserAgentSniff } from "./Bridge8thWall/DevHelpers";

//* Example stuff *//
import * as THREE from "three";
import { ICMuralScene } from "./ICMuralScene";

/* 8thWall to React-Three-Fiber Bridge */
/* We create our own Three.js Camera and WebGLRenderer objects in CustomThreePipeline.js */
/* R3F's renderer is not used and disabled, so any renderer props should be configured in CustomThreePipeline.js */
/* We use the Canvas and Scene provided by R3F, and tell R3F to use the pipeline Camera
/* https://www.8thwall.com/docs/ */

function App() {
  const customThreePipeline = useMemo(() => CreateThreePipeline(), []);

  /* switch component for dev assist */
  const use8thWall = true;//UserAgentSniff().mobileDetected;

  return (
    <Canvas
      style={{ height: "100%", width: "100%" }}
      webgl1={true} /* must force 8thwall to use webgl1 for iOS>Safari to work */
      concurrent={true}
      pixelRatio={devicePixelRatio}
    >
      {use8thWall && (
        <Bridge8thWall /* init 8thWall and provide with scene components */
          pipeline={customThreePipeline}
          markerTags={["FullWall_34P","FullWall_34"]} /* marker names you want active */
          markerScale={0.5} /* scale scene relative to the target */
          cameraPosition={[0, 3, 0]} /* must be greater than y=0 */
          worldTracking /* use 8thWall's world points tracking */
          staysVisible /* once visible, scene will never be hidden again, even on marker tracking loss (best used with worldTracking) */
        >
            <ICMuralScene />
        </Bridge8thWall>
      )}

      {!use8thWall && (
        <Emulate8thWall // Desktop browser development helper (remove for delivery)
          pipeline={customThreePipeline}
          cameraPosition={[0, 3, 0]}
          background={new THREE.Color("grey")}
          useOrbitControls={true}
        >
            <ICMuralScene />
        </Emulate8thWall>
      )}
    </Canvas>
  );
}

ReactDOM.render(<App />, document.getElementById("root"));