import { Vector3 } from 'three';
import {totalSize, cellTypes} from './Constants'
// import { lmapc, smoothstep } from './Utils/Remap';
// import { bezier } from 'bezier-easing'


// Each GridData will hold a finished state of an animation in it
// _svgData is an snapshot of the current state of the anim state of svgdata 
//
// Data looks like:
// 	[
//		[ {scale:Vector3}, {scale:Vector3}, {scale:Vector3}, ... ] ],
// 		[ {scale:Vector3} ],
//		[ {scale:Vector3}, {scale:Vector3}, {scale:Vector3}, ... ] ]
//		...
// 	]
class GridData {
	constructor(_svgData, startTime, _skip, _rowPct=0.5, _colPct=0.25){
		this.totalSize = {x: 1145.21, y:1857.6 };
		this.numRows = 8;
		this.numCols = 3;
		this.rowPcts = this.randomizeRowSize(_skip, _rowPct);
		this.colPcts = this.randomizeColSize(_skip, _colPct);
		this.animDur = 1.5;
		this.maxExtrude = 400.0;
		this.startTime = startTime;

		this.start = [];
		this.dest = [];

		for(let y=0; y<_svgData.length; y++){
			this.start[y] = [];
			this.dest[y] = [];

			for(let x=0; x<_svgData[y].length; x++){
				let c = _svgData[y][x];

				// normalizes the scales to 1, and then multiply them by the new row/col pcts
				let sc = new THREE.Vector3(
					(this.totalSize.x / c.size.x) * this.colPcts[x],
					(this.totalSize.y / c.size.y) * this.rowPcts[y],
					Math.random() * this.maxExtrude);

				// if the row is going to be hidden, scale the Z to zero as well
				if(this.colPcts[x] == 0.0 || this.rowPcts[y] == 0.0){
					sc.z = 0.0;
				}

				// if it's one of the long horizontal rows
				if(c.type == cellTypes.SPAN3){
					sc.x = 1.0;
				}

				// Save the starting scale
				this.start[y][x] = { scale:_svgData[y][x].scale.clone() };

				// set the destination scale
				this.dest[y][x] = { scale:sc };
			}
		}
	}

	getData(x, y, pct){
		let st = this.start[y][x].scale;
		let ds = this.dest[y][x].scale;

		let ns = new THREE.Vector3(
			st.x - (st.x - ds.x) * pct,
			st.y - (st.y - ds.y) * pct,
			st.z - (st.z - ds.z) * pct
		);

		return ns;
	}

	randomizeRowSize(skip = false, skipPct = 0.5){
		let tot = 0.0;
		let rowPcts = [];

		for(let i=0; i<this.numRows; i++){
			let min = 0.03;
			let max = 0.6;

			if(i%2 == 1){
				max = 0.2;
			}
			let r = Math.random() * (max-min) + min;
			rowPcts[i] = (1.0 - tot) * r;

			if(i == this.numRows-1){
				rowPcts[this.numRows-1] = 1.0 - tot;
			}
			
			tot = tot + rowPcts[i];
		}

		// if this is a cycle where a row gets compressed completely
		if(skip){
			// const skipPct = 0.5;	// higher value == more likely to skip

			// Decide which rows we will skip
			let skipArr = [];
			let skipTotal = 0.0;
			while(skipTotal == 0){	// you can't skip all the rows
				skipArr = rowPcts.map( (val) => { return Math.random() > skipPct ? val : 0.0 } );
				skipTotal = skipArr.reduce( (acc, val) => {return acc + val}, 0.0 );
			}

			// Count the number of rows we are skipping
			let numZeroRows = skipArr.reduce( (acc, val) => { 
				if(val == 0){
					return acc + 1.0
				}
				return acc; }, 0.0 );
			// Calculate the amount that the skipped rows would have taken up if they weren't skipped
			let exTotal = 1.0 - skipArr.reduce( (acc, val) => {return acc + val;}, 0.0 );

			// Distribute that amount evenly amongst the unskipped rows
			rowPcts.forEach( (val, index) => {
				if(skipArr[index] == 0){
					rowPcts[index] = 0;
				}else{
					rowPcts[index] += (exTotal / (rowPcts.length-numZeroRows));
				}
			});
		}

		return rowPcts;
	}

	randomizeColSize(skip = false, skipPct = 0.25){
		let tot = 0.0;
		const min = 0.03;
		const max = 0.75;
		let colPcts = [];

		// allocate random amounts to each column
		for(let i=0; i<this.numCols; i++){
			let r = Math.random() * (max-min) + min;
			colPcts[i] = (1.0 - tot) * r;

			if(i == this.numCols-1){
				colPcts[this.numCols-1] = 1.0 - tot;
			}

			tot = tot + colPcts[i];
		}

		// if this is a cycle where a column gets compressed completely
		if(skip){
			// const skipPct = 0.25;	// higher value == more likely to skip

			// Decide which cols we will skip
			let skipArr = [];
			let skipTotal = 0.0;
			while(skipTotal == 0){	// you can't skip all the cols
				skipArr = colPcts.map( (val) => { return Math.random() > skipPct ? val : 0.0 } );
				skipTotal = skipArr.reduce( (acc, val) => {return acc + val}, 0.0 );
			}


			// Count the number of cols we are skipping
			let numZeroCols = skipArr.reduce( (acc, val) => { 
				if(val == 0){
					return acc + 1.0
				}
				return acc; }, 0.0 );
			// Calculate the amount that the skipped cols would have taken up if they weren't skipped
			let exTotal = 1.0 - skipArr.reduce( (acc, val) => {return acc + val;}, 0.0 );

			// Distribute that amount evenly amongst the unskipped cols
			colPcts.forEach( (val, index) => {
				if(skipArr[index] == 0){
					colPcts[index] = 0;
				}else{
					colPcts[index] += (exTotal / (colPcts.length-numZeroCols));
				}
			});
		}
		return colPcts;
	}
}

// -----------------------------------------------------------------------------------------
export default class GridAnimator {

	constructor(_svgData){
		this.activeAnims = [];
		this.animOutput = [];
		this.svgData = _svgData;
		this.animOutput = this.recordStartingPos(this.svgData);
		let cubicbezier = require('bezier-easing');
		this.easing = cubicbezier(.76,.01,.21,1);
		this.lastSkip = true;
	}

	recordStartingPos(svgData){
		let ao = [];
		let py = 0.0;

		for(let y=0; y<svgData.length; y++){
			let px = 0.0;

			ao[y] = [];
			for(let x=0; x<svgData[y].length; x++){

				// get the original scale of the cells when compared to the total size;
				let scX = (svgData[y][x].type == cellTypes.SPAN3) ? 1.0 : svgData[y][x].size.x / totalSize.x;
				let scY = svgData[y][x].size.y / totalSize.y;

				// Bump Y every time we get to the first element of a new row
				if(x == 0){
					py -= (this.svgData[y][0].size.y * 1.0);
				}

				ao[y][x] = {
					scale: new Vector3(1.0, 1.0, 1.0),
					position: new Vector3(px, py, 0.0)
				};

				px += this.svgData[y][x].size.x * 1.0;
			}
		}

		return ao;
	}

	startNewAnim(_svgData, _animDur, clock){
		// console.log("GridAnimator :: new anim");

		// if we want to compress any rows/cols completely
		let skip = false;
		let rowPct = 0.5;
		let colPct = 0.8;

		if(this.lastSkip == false){
			if(Math.random() > 0.5){
				skip = true;
				rowPct = 0.75;
				colPct = 0.5;
			}
		}else if(Math.random() > 0.5){
			skip = true;
		}

		this.lastSkip = skip;

		// create a new gridData object which contains scales for all objects
		let gd = new GridData(_svgData, clock.elapsedTime, skip, rowPct, colPct);
		gd.animDur = _animDur;
		this.activeAnims.push(gd);
	}

	// elapsed time in seconds
	update(clock){
		let et = clock.elapsedTime;	// in seconds

		// remove finished animations
		this.activeAnims = this.activeAnims.filter(anim => et - anim.startTime < anim.animDur);

		// What is the total time for all animations running
		// This is calculated to see how much each aniamtions influeces the end position
		let totalTime = 0.0;
		this.activeAnims.forEach( (anim) => {totalTime += ((et - anim.startTime))});

		// calculate all the scales and positions
		let py = 0.0;
		let destScale = new Vector3(0,0,0);

		if(this.activeAnims.length){
			for(let y=0; y<this.svgData.length; y++){

				let px = 0.0;
				for(let x=0; x<this.svgData[y].length; x++){
					this.animOutput[y][x].position.set(0,0,0);

					destScale.set(0,0,0);

					// Scaling --------------
					// loop through all active animations and sum their input
					/*
					for(let i=0; i<this.activeAnims.length; i++){

						let aa = this.activeAnims[i];

						// how much this contributes to the final destination based on where it is in its anim
						let dt = (et - aa.startTime);
						let totalPct = dt / totalTime;
						// let apct = (et - aa.startTime) / aa.animDur * totalPct;

						let apct = Math.min(1.0, dt / aa.animDur);
											
						let animScale = aa.getData(x, y, 1.0);
						let influence = dt / totalTime;
						animScale.multiplyScalar(influence);

						destScale.add(animScale);
					}
					*/

					// let rate = 0.05;
					// this.animOutput[y][x].scale.add( destScale.sub(this.animOutput[y][x].scale).multiplyScalar(rate) );

					//////////////////////////////////////////////////////////////////////
					let a1 = this.activeAnims[0];
					let apct = Math.min(1.0, (et - a1.startTime) / a1.animDur);
					
					apct = this.easing(apct);
					// apct = smoothstep(0.0, 1.0, apct);

					let animDat = a1.getData(x, y, apct);
					this.animOutput[y][x].scale.copy(animDat);

					// Positioning ---------
					// every time we get to the first element in a row
					if(x == 0){
						py -= (this.svgData[y][0].size.y * this.animOutput[y][x].scale.y);
					}

					this.animOutput[y][x].position.set(
						px, 
						py,
						0.0
					);

					px += this.svgData[y][x].size.x * this.animOutput[y][x].scale.x;
				}
			}
		}	// end active anims

	}

	getData(){
		return this.animOutput;
	}
}