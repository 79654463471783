import React, { useRef, Suspense, useEffect, useState } from 'react';
import * as THREE from 'three';
import { useFrame } from "react-three-fiber";

import { SVGLoader } from 'three/examples/jsm/loaders/SVGLoader'

import {totalSize, cellTypes} from './Constants'
import * as svgDataImp from './SvgData';
import GridAnimator from './GridAnimator'
import ShapeGeometryAnim from './ShapeGeometryAnim';

let lastUpdate = 0.0;
const animDur = 1.5;
// const restDur = -0.2;
const restDur = 0.0;

let bLoadingFinished = false;
let bFirstRun = true;

let debugCube;

// GridAnimator
let animator;

// Make mutable copy
// This is our main data struct
let svgData = svgDataImp.default;

// ----------------------------------------------------------------
class SVGCell extends THREE.Group {
	
	constructor(_props){
		super();

		this.props = _props;
		let data = svgData[this.props.row][this.props.col];

		this.position.set(data.pos.x, data.pos.y, data.scale.z);
		this.scale.set(data.scale.x, data.scale.y, 1.0);

		// Create colored box geo 
		const geometry = new THREE.BoxGeometry( 1, 1, 1 );
		const material = new THREE.MeshStandardMaterial( {depthWrite:true, color:new THREE.Color(data.color).convertSRGBToLinear()} );

		this.svgExtrudeMesh = new THREE.Mesh( geometry, material );
		this.svgExtrudeMesh.receiveShadow = true;
		this.svgExtrudeMesh.castShadow = true;

		this.animatedGeo = [];

		// create SVG geo
		// console.log("----------------------------------------", data.svg);
		this.svgCont = new THREE.Group();
		// shapeList is an array of Threejs 'Shapes'
		this.props.shapeList.forEach( (shape, index) => {

			// console.log("Creating new SVG Shape", shape, index);

			// If there are animated layers of the svg, check the json for which layer and then turn them
			// into special ShapeGeometryAnim nodes. Otherwise use static geo
			let svGeo;
			let animList = [];
			try{ animList = data.anim.filter( a => a.layer == index); }
			catch(e){  }

			if(animList.length){
				// If we are here, there is an animation targeting this layer of the svg
				svGeo = new ShapeGeometryAnim(shape.shapes);
				animList.forEach( anim => svGeo.addAnim(anim) );

				if(data.seed){
					animList.forEach( anim => svGeo.setRandomSeed(data.seed) );
				}
				
				this.animatedGeo.push(svGeo);	// store so we can call its update later
			}else{
				// Normal static geo
				svGeo = new THREE.ShapeGeometry( shape.shapes );
			}

			const svgMat = new THREE.MeshStandardMaterial( {color: new THREE.Color(shape.color).convertSRGBToLinear(), depthWrite:false} );
			let svgMesh = new THREE.Mesh(svGeo, svgMat);
			svgMesh.receiveShadow = true;
			this.svgCont.add(svgMesh);
		})

		// we're going to flip it upside down so (0,0) is at the top left
		this.svgCont.position.set(0, data.size.y, 1.0);
		this.svgCont.scale.set(1.0, -1.0, 1.0);

		this.add(this.svgExtrudeMesh);
		this.add(this.svgCont);

		this.update();
	}

	update(et=0, pct=0) {
		let data = svgData[this.props.row][this.props.col];

		// if there are any secondary animations, update them here
		this.animatedGeo.forEach( shape => shape.update(et, pct) );

		this.position.set(data.pos.x, data.pos.y, data.scale.z);
 		this.scale.set(data.scale.x, data.scale.y, 1.0);

		this.svgExtrudeMesh.scale.set(data.size.x, data.size.y, data.scale.z);
		this.svgExtrudeMesh.position.set(data.size.x * 0.5, data.size.y * 0.5, data.scale.z * -0.5 - 1.0);
	}
}

// ---------------------------------------------------------------------------
export function ICMuralScene(props) {
	const [svgShapes, setShapes] = useState([]);
	const RootRef = useRef();

	let svgList = [];
	let mainCont;

	// console.log("IC MURAL SCENE -----------------------------", svgShapes);

	// svgShapes is an array of objects where each entry represents a cell in the grid
	// The first object is an array of Threejs 'Shape' objects that need to be turned into geo
	useEffect( ()=>{
		if(svgShapes.length == 16 && bLoadingFinished == false){
			mainCont = new THREE.Group();
			// mainCont.rotateX(-3.1415926*0.5);

			// LIGHTING
			let dirLight = new THREE.DirectionalLight(0xFFFFFF, 1.0);
			dirLight.castShadow = true;
			dirLight.shadow.mapSize.width = 2048;
			dirLight.shadow.mapSize.height = 2048;
			dirLight.shadow.bias = -0.00001;	// this is to mitigate shadow banding
			dirLight.position.set(0,10,10);

			let ambLight = new THREE.AmbientLight(0xFFFFFF, 0.2);

			// CONTAINERS
			let muralCont = new THREE.Group();
			muralCont.scale.set(0.0013, 0.0013, 0.0013);
			muralCont.position.set(-0.75, 1.4, 0);
			mainCont.add(muralCont);

			// Add all svg shapes
			svgShapes.forEach( (shapelist) => {
				let cell = new SVGCell(shapelist);
				svgList.push(cell);
				muralCont.add(cell);
			});

			// Add to 8th Wall scene
			mainCont.add(dirLight);
			mainCont.add(ambLight);
			RootRef.current.add(mainCont);

			// Set up animations
			animator = new GridAnimator( svgData );
			bLoadingFinished = true;
		}
	}, [svgShapes]);

	// LOAD ----------------------------------------------------------------------------------------------
	useEffect(()=>{
		let promiseList = [];
		svgData.forEach( (svgRow, rowIndex) => svgRow.forEach( (cell, colIndex) => {
			promiseList.push( new Promise( resolve => { new SVGLoader().load(cell.svg, resolve); } ) );
		}));

		// When all svgs are fully loaded, parse and start the experience
		Promise.all(promiseList).then(results => {
			console.log("* Loading finished *");

			let svgShapesTmp = [];

			let svgIndex = 0;
			svgData.forEach( (svgRow, rowIndex) => svgRow.forEach( (cell, colIndex) => {
				// SVG data is ordered correctly by which load was started first
				// Data is in a 1 dimensional array, so use extra index to keep track of pos
				let loadedData = results[svgIndex];
				svgIndex++;

				// convert the shape paths to threejs Shapes
				let shapeList = loadedData.paths.map(
					(group, index) => group.toShapes().map(
						(shapes) => ({shapes, color:group.color, uuid:shapes.uuid})
					)
				);

				let size = new THREE.Vector3();

				// flatten the array
				for( let i=0; i<shapeList.length; i++){
					shapeList[i] = shapeList[i][0];

					// measure the bounding box of the shape
					let sb = new THREE.ShapeGeometry( shapeList[i].shapes );
					var box = new THREE.Box3().setFromObject( new THREE.Mesh(sb) );
					let ls = new THREE.Vector3();
					box.getSize(ls);

					size.x = Math.max(size.x, ls.x);
					size.y = Math.max(size.y, ls.y);
					size.z = Math.max(size.z, ls.z);
				}

				let tp = svgData[rowIndex][colIndex].pos;
				let ts = svgData[rowIndex][colIndex].scale;

				svgData[rowIndex][colIndex].baseScale = new THREE.Vector3(size.x / totalSize.x, size.y / totalSize.y, 1.0);
				svgData[rowIndex][colIndex].destScale = new THREE.Vector3(1.0, 1.0, 1.0);
				svgData[rowIndex][colIndex].pos = new THREE.Vector3(tp[0], tp[1], 0.0);
				svgData[rowIndex][colIndex].scale = new THREE.Vector3(size.x / totalSize.x, size.y / totalSize.y, 1.0);
				svgData[rowIndex][colIndex].size = size;
				svgData[rowIndex][colIndex].type = (rowIndex%2 == 1) ? cellTypes.SPAN3 : cellTypes.SINGLE;

				let shapeObj = {shapeList, uuid:Date.now(), row:rowIndex, col:colIndex, dex:cell.dex, size:size};
				svgShapesTmp.push(shapeObj);
			}));

			// set the state variable which will trigger the experience to start
			setShapes( svgShapesTmp );
		});
	},[]);
	// end useEffect

	// Animation -------------------------
	useFrame( ({clock}) => {
		// if everything is loaded
		if(bLoadingFinished){

			// Update the primary grid animation
			animator.update( clock );
			let animData = animator.getData();
		
			if(animData.length){
				for(let y=0; y<svgData.length; y++){
					for(let x=0; x<svgData[y].length; x++){
						svgData[y][x].scale.copy( animData[y][x].scale );
						svgData[y][x].pos.copy( animData[y][x].position );
					}
				}
			}

			// Update the secondary cell animation
			let animPct = (clock.elapsedTime - lastUpdate ) / (animDur + restDur);
			svgList.forEach( cell => cell.update(clock.elapsedTime, animPct) );
		
			// Timer is up -----------------------------------------------------
			if(clock.elapsedTime >= lastUpdate + animDur + restDur ){
				lastUpdate = clock.elapsedTime;
				animator.startNewAnim(svgData, animDur, clock);
			}
		}
	});

	return (
		<group ref={RootRef}>
		</group>
	);
}