import * as THREE from "three";
import { useState, useEffect } from "react";
import { useThree } from "react-three-fiber";

//* Prints some debug info to the console *//
//* TODO: fix orbit controls conflict, add world bounds size *//

let clearConsole = true;

export default function DebugLogOnClick({ scene, camera, gl }) {
  const [reportTimeOut, setReportTimeOut] = useState(false);
  const { raycaster, mouse } = useThree();
  let data;

  useEffect(() => {
    console.log(
      "Click Object Info\n -'c' <toggle console clear [default: true]>\n -'d' <deliver console report [= mouse click]>\n - click background = scene/camera/gl info"
    );
  }, []);

  useEffect(() => {
    /// Event Listeners --->
    gl.domElement.addEventListener("mouseup", deliverReport, true);
    gl.domElement.addEventListener("mousedown", startClickTimer, true);
    return () => {
      gl.domElement.removeEventListener("mouseup", deliverReport, true);
      gl.domElement.removeEventListener("mousedown", startClickTimer, true);
    };
  }, [reportTimeOut]);
  useEffect(() => {
    window.addEventListener("keydown", handleKey, true);
    return () => window.removeEventListener("keydown", handleKey, true);
  }, []);

  const startClickTimer = (e) => {
    if (e.button == 0) {
      setReportTimeOut(true);
      setTimeout(() => {
        setReportTimeOut(false);
      }, 200);
    }
  };

  const deliverReport = (e, force) => {
    if ((e.button == 0 && reportTimeOut) || force) {
      if (scene.name == "") scene.name = "Scene";

      raycaster.setFromCamera(mouse, camera);
      let intersects = raycaster.intersectObjects(scene.children, true);

      // no ray intersect
      if (intersects.length == 0 || intersects[0].object == null) {
        data = makeReportData(camera, intersects[0]);
        consoleReport(data, camera, scene, gl);
      } else {
        // object intersect
        console.log(intersects[0]);
        data = makeReportData(intersects[0].object, intersects[0]);
        consoleReport(data);
      }
    }
  };

  const handleKey = (e) => {
    if (e.key === "c") {
      clearConsole = !clearConsole;
      console.log(
        clearConsole
          ? "Console auto-clear enabled."
          : "Console auto-clear disabled."
      );
    }
    if (e.key === "d") deliverReport(e, true);
  };

  return null;
}

const makeReportData = (obj, intersect) => {
  //if (obj instanceof THREE.Camera && obj.name == "") obj.name = "Camera";
  let reportData = {
    object: obj,
    intersect: intersect,
    name:
      obj.name != "" ? obj.type + " | " + obj.name : obj.type + " | <unnamed>",
    worldPosition: new THREE.Vector3(),
    worldScale: new THREE.Vector3(),
    worldQuaternion: new THREE.Quaternion(),
    worldEuler: new THREE.Euler(),
    parents: [],
    clickPointWorld: intersect ? intersect.point : new THREE.Vector3(),
    distanceFromCamera: intersect ? intersect.distance : 0,
  };

  obj.getWorldPosition(reportData.worldPosition);
  obj.getWorldScale(reportData.worldScale);
  obj.getWorldQuaternion(reportData.worldQuaternion);
  reportData.worldQuaternion.normalize();
  reportData.worldEuler.setFromQuaternion(reportData.worldQuaternion);

  reportData.parents = obj.parent != null ? climbObjectTree(obj) : [];
  reportData.parents.unshift({ name: reportData.name });
  if (obj instanceof THREE.InstancedMesh)
    reportData.parents.unshift({
      name: "Mesh Instance ID: " + intersect.instanceId,
    });
  return reportData;
};

const consoleReport = (data, ...props) => {
  console.log({ data });
  if (clearConsole) console.clear();
  if (!clearConsole) console.groupCollapsed(data.name);
  else console.log(data.name);
  console.log(
    "\nPosition: " +
      formatVec3Tab(data.worldPosition, 2) +
      "\n\nRotation: " +
      formatVec3Tab(data.worldEuler, 2) +
      "\n\nScale:" +
      formatVec3Tab(data.worldScale, 2)
  );

  // camera -->dd
  if (data.object == props[0]) {
    console.log(props[1]);
    console.log(props[2].info);
  } else {
    // scene object -->
    let tree = "\nHierarchy: \n";
    let fill = "⤷ ";

    for (let i = data.parents.length - 1; i >= 0; i--) {
      const obj = data.parents[i];

      if (i == data.parents.length - 1) tree += obj.type + " | " + obj.name;
      else {
        const type = i !== 0 ? obj.type + " | " : "";
        tree += "\n" + fill + type + obj.name;
        fill = " " + fill;
      }
    }
    //tree += "\n" + fill + data.name + "\n ";
    console.log(tree);

    console.log(
      "Point clicked in world space:\n" + formatVec3(data.clickPointWorld, 2)
    );
    console.log("Distance from camera:\n" + data.distanceFromCamera.toFixed(3));
  }

  console.log("Object", data.object);
  if (data.intersect) console.log("Raycast Intersect", data.intersect);
  console.groupEnd();
};

const formatVec3Tab = (vec3, precision) => {
  return (
    "\nX: " +
    vec3.x.toFixed(precision) +
    "\nY: " +
    vec3.y.toFixed(precision) +
    "\nZ: " +
    vec3.z.toFixed(precision)
  );
};

const formatVec3 = (vec3, precision) => {
  return (
    "X: " +
    vec3.x.toFixed(precision) +
    " Y: " +
    vec3.y.toFixed(precision) +
    " Z: " +
    vec3.z.toFixed(precision)
  );
};

const climbObjectTree = (obj) => {
  const parents = [];
  const max = 25;
  let i = 0;

  const climb = (o) => {
    i++;
    if (o.parent != null && i <= max) {
      parents.push(o.parent);
      climb(o.parent);
    }
  };

  climb(obj);
  return parents;
};
