import React, {
  useEffect,
  useState,
  useRef,
  useCallback,
  useMemo,
} from "react";
import { useThree, useFrame, extend } from "react-three-fiber";
import * as THREE from "three";
window.THREE = THREE; /* required by 8thWall */

//* 8thWall custom behaviors pipeline *//
function CustomPipelineModule(props) {
  const {
    scene,
    customThreePipeline,
    setRefs,
    onMarkerFound,
    onMarkerLost,
    onMarkerDetailUpdate,
    cameraPosition,
  } = props;
  let refs = null;

  const listeners = [
    { event: "reality.imagefound", process: onMarkerFound },
    {
      event: "reality.imageupdated",
      process: onMarkerDetailUpdate,
    },
    { event: "reality.imagelost", process: onMarkerLost },
  ];

  const onStart = ({ canvas }) => {
    const { camera, gl } = customThreePipeline.getCustomThreeObjects();

    /* required for 8thWall camera feed */
    gl.autoClear = false;

    camera.position.fromArray(cameraPosition);

    refs = {
      camera: camera,
      gl: gl,
    };

    setRefs(refs);

    /* sync the xr controller's 6DoF position and camera paremeters with our scene. */
    XR8.XrController.updateCameraProjectionMatrix({
      origin: camera.position,
      facing: camera.quaternion,
    });
  };

  const onRender = () => {
    if (refs) {
      refs.gl.clearDepth();
      refs.gl.render(scene, refs.camera);
    }
  };

  return {
    /* name can be anything but is required */
    name: "basic_three_pipeline",
    listeners: listeners,
    onStart,
    onRender,
  };
}

//* 8thWall Bridge *//
export function Bridge8thWall({
  pipeline,
  markerTags,
  markerRotation,
  markerScale,
  worldTracking,
  cameraPosition,
  tapToShow,
  startsVisible,
  staysVisible,
  ...props
}) {
  const customThreePipeline = pipeline;
  const [loaded, setLoaded] = useState(false);
  const [started, setStarted] = useState(false);
  const [visible, setVisible] = useState(startsVisible ? true : false);
  const [refs, setRefs] = useState(null);
  const { setDefaultCamera, scene } = useThree();
  const AR_Group = useRef();

  /* disable R3F's rendering, render call comes from 8thWall's pipeline */
  useFrame(() => null, 1);

  /* callback to start when framework loaded */
  useEffect(() => {
    XRExtras.Loading.showLoading({ onxrloaded });

    // custom loading image
    const loadImage = document.getElementById("loadImage");
    if (loadImage) {
      loadImage.src = "/load-gradv.png";
    }


    scene.add(AR_Group.current);
  }, []);

  /* refs returned from custom pipeline module */
  useEffect(() => {
    if (refs) {
      setDefaultCamera(refs.camera);
      setStarted(true);
      // console.log(refs);
    }
  }, [refs]);

  //* Start 8thWall *//
  useEffect(() => {
    if (!loaded) return;

    console.log("Bridge8thWall - Loaded, Starting");

    XR8.XrController.configure({
      imageTargets: markerTags,
      disableWorldTracking: !worldTracking,
    });
    XR8.addCameraPipelineModules([
      XR8.GlTextureRenderer.pipelineModule() /* Draws the camera feed. */,
      // XR8.Threejs.pipelineModule(), /* [removed - customThreePipeline instead] */
      customThreePipeline.pipelineModule() /* custom three pipeline */,
      XR8.XrController.pipelineModule() /* Enables SLAM tracking. */,
      XRExtras.AlmostThere.pipelineModule() /* Detects unsupported browsers and gives hints. */,
      // XRExtras.FullWindowCanvas.pipelineModule(), /* [removed - use R3F's canvas & custom styling instead] */
      XRExtras.Loading.pipelineModule() /* Manages the loading screen on startup. */,
      XRExtras.RuntimeError.pipelineModule() /* Shows an error image on runtime error. */,
      CustomPipelineModule(customPipelineProps) /* custom behavior module */,
    ]);

    let canvasElement = document.getElementsByTagName("canvas")[0];
    XR8.run({ canvas: canvasElement, verbose: false });

    return () => {
      let canvasElement = document.getElementsByTagName("canvas")[0];
      canvasElement.parentNode.removeChild(canvasElement);
      XR8.stop();
      XR8.clearCameraPipelineModules();
    };
  }, [loaded]);

  const onxrloaded = useCallback(() => {
    setLoaded(true);
  });

  const onMarkerFound = useCallback(() => {
    console.log("Marker Found");
    setVisible(staysVisible ? true : true);
  });

  const onMarkerLost = useCallback(() => {
    console.log("Marker Lost");
    setVisible(staysVisible ? true : false);
  });

  const rotOffset = useMemo(() => {
    return markerRotation
      ? new THREE.Quaternion().setFromEuler(
          new THREE.Euler().fromArray(markerRotation)
        )
      : new THREE.Quaternion().identity();
  }, [markerRotation]);

  const onMarkerDetailUpdate = useCallback(
    ({ detail }) => {
      /* this updates every frame the marker is tracking */
      if (markerTags && markerTags.includes(detail.name)) {
        AR_Group.current.position.copy(detail.position);
        AR_Group.current.quaternion.copy(detail.rotation).multiply(rotOffset);
        AR_Group.current.scale.setScalar(detail.scale * (markerScale || 1));
      }
    },
    [markerTags]
  );

  const customPipelineProps = {
    scene: scene,
    customThreePipeline: customThreePipeline,
    setRefs: setRefs,
    onMarkerFound: onMarkerFound,
    onMarkerLost: onMarkerLost,
    onMarkerDetailUpdate: onMarkerDetailUpdate,
    cameraPosition: cameraPosition,
  };

  useEffect(() => {
    if (tapToShow) {
      window.addEventListener("touchstart", () => setVisible(true), true);
    }

    return () =>
      window.removeEventListener("touchstart", () => setVisible(true), true);
  }, [tapToShow]);

  return (
    <group name={"8thWall_AR_Group"} ref={AR_Group} visible={visible}>
      {started && props.children}
    </group>
  );
}
